import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "using-roles-or-scopes-with-auth0"
    }}>{`Using roles or scopes with Auth0`}</h1>
    <p><a parentName="p" {...{
        "href": "https://useauth.dev"
      }}><inlineCode parentName="a">{`useAuth`}</inlineCode></a>{` comes with built-in support for authorization.`}</p>
    <p>{`To use it with Auth0, you'll need to configure a couple things on Auth0 and 1 on useAuth.`}</p>
    <h2 {...{
      "id": "1-add-user-roles-to-metadata"
    }}>{`1. Add user roles to metadata`}</h2>
    <p>{`Go to your `}<a parentName="p" {...{
        "href": "https://manage.auth0.com/dashboard"
      }}>{`Auth0 Dashboard`}</a>{` and find the `}<inlineCode parentName="p">{`Rules`}</inlineCode>{` section.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0797ad7862580b0da3d6b7ee6804f333/d3bd3/auth0-rules.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "28.699551569506728%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'114\\'%20viewBox=\\'0%200%20400%20114\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M334%209l-1%208v8h61V8h-30l-30%201M211%2098c-2%201-3%206-1%208s15%201%2016-1c3-3%202-5%200-7-3-3-11-3-15%200\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/0797ad7862580b0da3d6b7ee6804f333/ca0a1/auth0-rules.webp 223w", "/static/0797ad7862580b0da3d6b7ee6804f333/75680/auth0-rules.webp 445w", "/static/0797ad7862580b0da3d6b7ee6804f333/8d1ba/auth0-rules.webp 890w", "/static/0797ad7862580b0da3d6b7ee6804f333/3838e/auth0-rules.webp 1335w", "/static/0797ad7862580b0da3d6b7ee6804f333/e11e5/auth0-rules.webp 1780w", "/static/0797ad7862580b0da3d6b7ee6804f333/2292e/auth0-rules.webp 2822w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/0797ad7862580b0da3d6b7ee6804f333/e92b6/auth0-rules.png 223w", "/static/0797ad7862580b0da3d6b7ee6804f333/e66bf/auth0-rules.png 445w", "/static/0797ad7862580b0da3d6b7ee6804f333/4ef49/auth0-rules.png 890w", "/static/0797ad7862580b0da3d6b7ee6804f333/4e814/auth0-rules.png 1335w", "/static/0797ad7862580b0da3d6b7ee6804f333/701e9/auth0-rules.png 1780w", "/static/0797ad7862580b0da3d6b7ee6804f333/d3bd3/auth0-rules.png 2822w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0797ad7862580b0da3d6b7ee6804f333/4ef49/auth0-rules.png",
                "alt": "Auth0 Rules dashboard",
                "title": "Auth0 Rules dashboard",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Auth0 Rules dashboard`}</figcaption>{`
  `}</figure></p>
    <p>{`Create a new blank rule, name it whatever, and paste this code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function (user, context, callback) {
    // replace YOUR_DOMAIN with your domain
    const namespace = 'https://YOUR_DOMAIN';
    const assignedRoles = (context.authorization || {}).roles;
    user.user_metadata = user.user_metadata || {};
    user.user_metadata.roles = assignedRoles;
    context.idToken[namespace + '/user_metadata'] = user.user_metadata;
    callback(null, user, context);
}
`}</code></pre>
    <p>{`Auth0 runs this rule when useAuth fetches user data. The code adds user roles to `}<inlineCode parentName="p">{`user_metadata`}</inlineCode>{` passed back to `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{`.`}</p>
    <p>{`Make sure you replace `}<inlineCode parentName="p">{`YOUR_DOMAIN`}</inlineCode>{` with your actual domain. Any domain will do as long as the string starts with `}<inlineCode parentName="p">{`https://`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "2-configure-custompropertynamespace"
    }}>{`2. Configure customPropertyNamespace`}</h2>
    <p><inlineCode parentName="p">{`useAuth`}</inlineCode>{` reads user_metadata from a customPropertyNamespace. You need to configure this in your `}<inlineCode parentName="p">{`<AuthConfig />`}</inlineCode>{` call.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{` <AuthConfig
    authProvider={Providers.NetlifyIdentity}
    navigate={(url) => router.push(url)}
    params={{
        domain: "useauth.auth0.com"
        clientID: "GjWNFNOHqlino7lQNjBwEywalaYtbIzh",
        customPropertyNamespace: "https://YOUR_DOMAIN"
    }}
/>
`}</code></pre>
    <p>{`Replace `}<inlineCode parentName="p">{`YOUR_DOMAIN`}</inlineCode>{` with the same domain you used above.`}</p>
    <h2 {...{
      "id": "3-isauthorized"
    }}>{`3. isAuthorized`}</h2>
    <p>{`You can now use `}<a parentName="p" {...{
        "href": "/docs/roles"
      }}>{`isAuthorized`}</a>{` to verify user authorization.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      